import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import logo from "../../Assets/logo.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import Auth from "./Components/Auth/Auth";
import Admin from "./Components/Admin/Admin";
import { ExitToAppOutlined } from "@material-ui/icons";
import Game from "./Components/Game/Game";
import { api } from "../../shared/user-api";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(0, 0, 0, 1),
    flexGrow: 1,
  },
  logo: {
    height: 60,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4, 2),
    maxWidth: 1225,
    margin: "auto",
    textAlign: "center",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  card: {
    // minWidth: 275,
    background: "#000",
  },
  toolbar: {
    borderBottom: "1px solid #222",
    padding: theme.spacing(0, 2, 0, 1),
    [theme.breakpoints.up(1225)]: {
      padding: "0 calc(calc(calc(100% - 1225px) / 2) + 8px)",
    },
  },
  authContainer: {
    marginTop: "calc(60vh - 180px)",
  },
  authForm: {
    display: "grid",
    justifyItems: "center",
    "& .MuiTextField-root": {
      width: "250px",
      margin: theme.spacing(1),
    },
    "& .MuiButton-root": {
      width: "250px",
      margin: theme.spacing(1),
      border: "1px solid #fff",
    },
  },
}));

function AdminApp() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [authStep, setAuthStep] = useState(0);
  const [me, setMe] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [auth, setAuth] = React.useState(true);
  const [clubs, setClubs] = React.useState([]);

  const history = useHistory();

  const onError = () => {
    setLoading(false);
    setAuth(false);
    history.push("/admin/auth");
  };

  const updateUserData = async () => {
    const userDataRes = await api.user.getMe();

    if (!userDataRes.success) {
      if (me) setMe(null);
      if (userDataRes.errors.server) setErrorMessage(userDataRes.errors.server);
      throw new Error("user not found");
    }

    if (
      !userDataRes.user.permissions ||
      Object.keys(userDataRes.user.permissions).length == 0
    ) {
      await api.user.logout();
      history.push("/admin/auth");

      setErrorMessage("У вас нет прав на ведение игр");
      return;
    }

    setMe(userDataRes.user);
    setLoading(false);
  };

  const updatePlacesData = async () => {
    const clubsRes = await api.user.getClubs();

    if (!clubsRes.success) {
      if (clubs) setClubs([]);
      return;
    }

    setClubs(clubsRes.clubs);
  };

  const updateData = async () => {
    try {
      await updateUserData();
      await updatePlacesData();
      setAuth(true);
    } catch (error) {
      onError();
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  const onAuth = async (phone, type, code) => {
    setErrorMessage("");

    const signInRes = await api.auth.signIn({
      phone: phone,
      code: code,
      verification_type: type,
    });

    if (!signInRes.success) {
      setErrorMessage(
        signInRes.errors.server ||
          signInRes.errors.phone ||
          signInRes.errors.code ||
          signInRes.errors.verification_type
      );
      return;
    }

    updateData();
    history.push("/admin");
  };

  const onCreateGame = async (type, place, count, club, city) => {
    let gameData = await api.games.createGame({
      active: false,
      type: type,
      playground: place,
      count: count,
      club,
      city,
    });
    if (!gameData?.success) return;
    history.push(`/admin/game/${gameData.game.id}`);
  };

  const onLoadGame = async (fileData) => {
    let fr = new FileReader();
    fr.onload = async (e) => {
      let lines = e.target.result;
      var newData = JSON.parse(lines);
      if (newData?.id) {
        let gameData = await api.games.updateGame(newData.id, newData);
        if (!gameData?.success) return;
        history.push(`/admin/game/${gameData.game.id}`);
      }
    };
    fr.readAsText(fileData);
  };

  const onLogout = async () => {
    try {
      await api.auth.logout();
    } catch (error) {
      console.log(error);
    } finally {
      onError();
    }
  };

  const onSendCode = async (phone, type) => {
    setErrorMessage("");

    const signInCodeRes = await api.auth.sendSignInCode({
      phone: phone,
      verification_type: type,
    });

    if (!signInCodeRes.success) {
      setErrorMessage(
        signInCodeRes.errors.server ||
          signInCodeRes.errors.phone ||
          signInCodeRes.errors.verification_type
      );
      return;
    }

    setAuthStep(1);
  };

  if (loading)
    return (
      <div style={{ margin: "32px auto", width: "fit-content" }}>
        <CircularProgress color="secondary" />
      </div>
    );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="menu"
					>
						<MenuIcon />
					</IconButton> */}
          <img className={classes.logo} src={logo} alt={"logo"} />
          <Typography variant="h6" className={classes.title}>
            <Link component={Button} color="inherit" to="/admin">
              Главная
            </Link>
          </Typography>
          <div></div>
          <div>
            {auth ? (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="Выйти"
                onClick={onLogout}
              >
                <ExitToAppOutlined />
              </IconButton>
            ) : (
              <></>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route
          path="/admin/auth"
          children={
            <Auth
              error={errorMessage}
              onAuth={onAuth}
              step={authStep}
              onClearStep={() => setAuthStep(0)}
              onSendCode={onSendCode}
            />
          }
        />
        <Route path="/admin/game/:gameId" children={<Game />} />
        <Route
          path="/admin"
          children={
            me ? (
              <Admin
                me={me}
                onError={onError}
                onCreateGame={onCreateGame}
                onLoadGame={onLoadGame}
                clubs={clubs}
              />
            ) : (
              <></>
            )
          }
        />
      </Switch>
    </div>
  );
}

export default AdminApp;
